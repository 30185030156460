import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import StyledHeading from "../components/StyledHeading"
import { Link } from "gatsby"

const Sitemap = ({ data }) => {
  return (
    <Layout>
      <SEO title="Sitemap" description="" />
      <div className="container px-6 mx-auto mt-16 md:mt-20">
        <StyledHeading tag="h1" text="Sitemap" size="text-3xl" />
        <ul className="mt-10">
          <li>
            <Link to="/">Homepage</Link>
          </li>
          <li>
            <Link to="/over-moi/">Over moi</Link>
          </li>
          <li>
            <Link to="/blog/">Blogs</Link>
          </li>
          <li>
            <Link to="/contact/">Contact</Link>
          </li>
        </ul>
        <h2 className="mt-10 text-2xl font-bold uppercase">Blogs</h2>
        <ul className="mt-5">
          {data.allMarkdownRemark.nodes.map((node, index) => (
            <li key={index}>
              <Link to={`/blog/${node.frontmatter.slug}/`} className="italic">
                {node.frontmatter.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          title
          slug
        }
      }
    }
  }
`

export default Sitemap
